// Sanatize functions
function sanitizeText( textValue ) {
	return DOMPurify.sanitize( textValue )
}

function sanitizeHTML(html) {
    const parser = new DOMParser().parseFromString(html, 'text/html')
    const cleanHtml =  parser.body.textContent.trim() || "" 
	return DOMPurify.sanitize( cleanHtml )
}

// Helper function for fetching posts with error handling
async function fetchJsonData(apiUrl) {
    try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }
		const data = await response.json()
        // ensure data is clean
		return data.map(post => ({
            title: { rendered: sanitizeText(post.title.rendered) },
            link: post.link,
            featured_image: post.featured_image || null,
            excerpt: { rendered: sanitizeHTML(post.excerpt.rendered) }
        }));

    } catch (error) {
        console.error("Error fetching posts:", error)
        return [] // Return an empty array on failure to prevent crashes
    }
}

// Fetch stories from the API
async function fetchStories(wrapperElement, dataSetMap) {
    const apiURL = `https://news.uvic.ca/wp-json/wp/v2/stories?_fields=title,link,featured_image,excerpt&keywords=${encodeURIComponent(dataSetMap.uvKeywords)}&per_page=${dataSetMap.uvRows}`
	const results = await fetchJsonData(apiURL)
    processData(wrapperElement, results, dataSetMap)
}

// Display functions
function displayBulletList( posts ) {
	const fragment = document.createDocumentFragment()
	posts.forEach((post) => {
		const li = document.createElement("li")
		const a = document.createElement("a")
		a.href = post.link
		a.textContent = post.title.rendered
		li.appendChild(a)
		fragment.appendChild(li)
	})
	return fragment
}

function displayStoryList( posts ) {
	const fragment = document.createDocumentFragment() // Optimize DOM updates
	posts.forEach(post => {
		const card = document.createElement('div')
		card.classList.add('card', 'feed__item', 'feed__item--story')

		const row = document.createElement('div')
		row.classList.add('row', 'g-0')

		if (post.featured_image) {
			const imgCol = document.createElement('div')
			imgCol.classList.add('col-md-4')
			const link = document.createElement('a')
			link.href = post.link
			link.classList.add('feed__link')

			const img = document.createElement('img')
			img.src = post.featured_image.full.url
			img.alt = `Open ${ post.title.rendered }`
			img.classList.add('card-img', 'feed__img')

			link.appendChild(img)
			imgCol.appendChild(link)
			row.appendChild(imgCol)
		}

		const textCol = document.createElement('div')
		textCol.classList.add(post.featured_image ? 'col-md-8' : 'col-md-12')

		const cardBody = document.createElement('div')
		cardBody.classList.add('card-body')

		const title = document.createElement('h3')
		title.classList.add('card-title', 'feed__title')

		const titleLink = document.createElement('a')
		titleLink.href = post.link
		titleLink.classList.add('feed__link', 'stretched-link')
		titleLink.textContent = post.title.rendered

		// Arrow icon
		const icon = document.createElement('i')
		icon.classList.add('far', 'fa-square-arrow-up-right', 'ms-1')
		icon.setAttribute('aria-hidden', 'true')

		titleLink.appendChild(icon)
		title.appendChild(titleLink)

		const text = document.createElement('p')
		text.classList.add('card-text', 'feed__text')
		
		text.textContent = post.excerpt.rendered

		cardBody.appendChild(title)
		cardBody.appendChild(text)
		textCol.appendChild(cardBody)
		row.appendChild(textCol)
		card.appendChild(row)
		fragment.appendChild(card)
	})

	return fragment
}

function displayCardList( posts ) {
	const fragment = document.createDocumentFragment() // Use a fragment to improve performance

	posts.forEach(post => {
		const col = document.createElement('div')
		col.classList.add('col')

		const card = document.createElement('div')
		card.classList.add('card')

		const cardWrapper = document.createElement('div')
		cardWrapper.classList.add('card-wrapper')

		if (post.featured_image) {
			const img = document.createElement('img')
			img.src = post.featured_image.full.url
			img.alt = `Open ${post.title.rendered}`
			img.classList.add('card-img-top')
			cardWrapper.appendChild(img)
		}

		const cardBody = document.createElement('div')
		cardBody.classList.add('card-body')

		const title = document.createElement('h3')
		title.classList.add('card-title', 'h5')

		const link = document.createElement('a')
		link.href = post.link
		link.classList.add('stretched-link')

		link.textContent = post.title.rendered

		title.appendChild(link)
		cardBody.appendChild(title)
		cardWrapper.appendChild(cardBody)
		card.appendChild(cardWrapper)
		col.appendChild(card)
		fragment.appendChild(col)
	})

	return fragment
}

function displayEmptyResults(displayType) {
    if (displayType === 'Bullet list') {
        const listItem = document.createElement('li');
        listItem.textContent = "No stories returned";
        return listItem;
    } else {
        const messageElement = document.createElement('p');
        messageElement.textContent = "No stories returned";
        return messageElement;
    }
}


// Process and display data
function processData(wrapperElement, results, dataSetMap) {
    // Adjust height dynamically 
    // wrapperElement.style.minHeight = `${27 * Math.max(results.length, 1)}px`;

    // display content
    const displayType = dataSetMap.uvDisplayType;
    let listElement = "";
    if (results.length < 1) {
        listElement = displayEmptyResults(displayType);
    } else if (displayType === 'Story list') {
        listElement = displayStoryList(results);
    } else if (displayType === 'Card list') {
        listElement = displayCardList(results);
    } else {
        // default to bullet list
        listElement = displayBulletList(results);
    }

    // Clear and update the wrapper element
    wrapperElement.innerHTML = ""; // Remove previous content
    wrapperElement.appendChild(listElement);
}

// loop through all feeds on the page.
const wrappers = document.querySelectorAll(".news-wrapper").forEach( (element) => {
	fetchStories(element, element.dataset)
})